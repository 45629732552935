import { Component, Inject, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { ToasterJoinVideoCallMessageContent } from '../../../shared/models/Toaster';
import { AppState } from '../../../shared/store/app-state';
import { removeToasterMessage } from '../../../site-detail/store/actions/ui';
import { VideoCallModal } from '../../services/video-call-modal';
import { VideoCallProvider } from '../../services/VideoCallProvider';
import { VideoCallsNotificationActions } from '../../store/actions/videoCall';

@Component({
    selector: 'app-video-call-toaster-item',
    standalone: true,
    imports: [TranslateModule],
    templateUrl: './video-call-toaster-item.component.html',
    styleUrl: './video-call-toaster-item.component.scss',
})
export class VideoCallToasterItemComponent {
    @Input() content: ToasterJoinVideoCallMessageContent;
    @Input() messageId: string;

    constructor(
        private store: Store<AppState>,
        private videoCallModal: VideoCallModal,
        @Inject('VideoCallProvider') private videoCallProvider: VideoCallProvider,
    ) {}

    onClose() {
        this.store.dispatch(removeToasterMessage({ id: this.messageId }));
    }

    handleVideoCallToasterAction() {
        const installerHasVideoCallInstance = this.videoCallProvider.hasVideoCallInstance();

        if (!installerHasVideoCallInstance) {
            this.openVideoCallModal();
        }

        this.onClose();
    }

    openVideoCallModal(): void {
        this.store.dispatch(VideoCallsNotificationActions.videoCallOpened());
        this.videoCallModal.open({
            siteId: this.content.siteId,
            url: this.content.url,
            ownerToken: this.content.ownerToken,
        });
    }
}
