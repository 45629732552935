import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { VideoCall, VideoCallId } from '../models/video-call';
import { VideoCallGateway } from './VideoCallGateway';

@Injectable()
export class ResendVideoCallInvitationUseCase {
    constructor(@Inject('VideoCallGateway') private videoCallGateway: VideoCallGateway) {}

    exec(videoCallId: VideoCallId): Observable<VideoCall> {
        return this.videoCallGateway.resendVideoCallInvitation(videoCallId);
    }
}
