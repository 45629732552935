import { createFeatureSelector, createReducer, on } from '@ngrx/store';
import { VIDEO_CALL_SCREEN_MODE, VideoCallScreenMode } from '../../utils/video-call-dialog-config';
import { UiVideoCallsActions } from '../actions/uiVideoCall';

export type UiVideoCallsState = { screenMode: VideoCallScreenMode };

export const UI_VIDEO_CALLS_INITIAL_STATE: UiVideoCallsState = { screenMode: VIDEO_CALL_SCREEN_MODE.FULLSCREEN };

export default createReducer(
    UI_VIDEO_CALLS_INITIAL_STATE,

    on(UiVideoCallsActions.videoCallScreenModeToggled, (state, { screenMode }): UiVideoCallsState => {
        if (state.screenMode === screenMode) {
            return state;
        }
        return {
            ...state,
            screenMode,
        };
    }),
);

export const getUiVideoCallsState = createFeatureSelector<UiVideoCallsState>('ui-video-calls');
