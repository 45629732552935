<div class="seg-modal">
  <mat-dialog-content class="seg-modal-body">
    <img src="assets/img/alert.svg"/>
    <span>{{ data.warningMessage | translate }}</span>
  </mat-dialog-content>
  <mat-dialog-actions class="seg-flex-justify-space-around">
    <button id="btn-cancel"
            class="cancel-btn seg-btn seg-bg-color-secondary"
            mat-button
            mat-dialog-close
            (click)="cancelDelete()"
    >{{ 'CANCEL' | translate }}</button>
    <button id="btn-validate"
            class="ok-btn seg-btn seg-bg-color-primary"
            mat-button
            mat-dialog-close
            (click)="confirmDelete();"
    >{{ 'DELETE' | translate }}</button>
  </mat-dialog-actions>
</div>
