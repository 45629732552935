<div
  class="video-call-wrapper {{ screenMode }}"
  cdkDrag
  cdkDragBoundary=".cdk-overlay-container"
  cdkDragRootElement=".cdk-overlay-pane"
  [cdkDragDisabled]="screenMode === VIDEO_CALL_SCREEN_MODE.FULLSCREEN"
>
  <div class="video-container">
    <app-video-call-fullscreen-button [screenMode]="screenMode" />

    @if (videoParticipants$ | async; as videoParticipants) {
      @if (videoParticipants?.guest) {
        <app-video-tile [participant]="videoParticipants.guest" />
      }
    }
  </div>

  <app-video-call-controls [siteId]="videoCallData.siteId" [screenMode]="screenMode" />
</div>
