import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { AnalyticsService } from '../../../shared/services/analytics.service';
import {
    AlertResolutionPageActions,
    InstallationPageActions,
    VideoCallsNotificationActions,
} from '../actions/videoCall';

@Injectable()
export class VideoCallAnalyticsEffects {
    constructor(
        private actions$: Actions,
        private analyticsService: AnalyticsService,
    ) {}

    logVideoCallInvitationSent$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    InstallationPageActions.startVideoCallRequested,
                    AlertResolutionPageActions.startVideoCallRequested,
                ),
                tap(({ type: eventType }) => {
                    const source = eventType.includes('[Installation Page]') ? 'Installation' : 'Alert resolution';
                    this.analyticsService.videoCallInvitationSent(source);
                }),
            ),
        { dispatch: false },
    );

    logVideoCallJoined$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    VideoCallsNotificationActions.videoCallOpened,
                    InstallationPageActions.videoCallOpened,
                    AlertResolutionPageActions.videoCallOpened,
                ),
                tap(({ type: eventType }) => {
                    let source = 'Toast';

                    if (eventType.includes('[Installation Page]')) {
                        source = 'Installation';
                    } else if (eventType.includes('[Alert Resolution Page]')) {
                        source = 'Alert resolution';
                    }

                    this.analyticsService.videoCallJoined(source);
                }),
            ),
        { dispatch: false },
    );
}
