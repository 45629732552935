import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { VideoCallModalComponent } from '../components/video-call-modal/video-call-modal.component';
import { VideoCallModalData } from '../models/video-call';

@Injectable({
    providedIn: 'root',
})
export class VideoCallModal {
    private dialogRef: MatDialogRef<VideoCallModalComponent>;

    constructor(private dialog: MatDialog) {}

    open(videoCallModalData: VideoCallModalData): void {
        this.dialogRef = this.dialog.open(VideoCallModalComponent, {
            disableClose: true,
            panelClass: 'video-call-modal',
            backdropClass: 'video-call-modal-backdrop',
            width: '100%',
            maxWidth: '95vw',
            height: '95vh',
            data: {
                siteId: videoCallModalData.siteId,
                url: videoCallModalData.url,
                ownerToken: videoCallModalData.ownerToken,
            },
        });
    }

    close(): void {
        if (this.dialogRef) {
            this.dialogRef.close();
            this.dialogRef = undefined;
        }
    }
}
