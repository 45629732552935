import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, switchMap, tap } from 'rxjs/operators';
import { ToasterMessageType, ToasterVideoCallInvitationSentMessage } from '../../../shared/models/Toaster';
import { addToasterMessage } from '../../../shared/store/actions/addToasterMessage';
import { startLoading } from '../../../shared/store/actions/user-env';
import { AppState } from '../../../shared/store/app-state';
import { getSiteOwner } from '../../../site-detail/store/selectors/site';
import { getUserSiteName } from '../../../site-detail/utils/owner.util';
import { VideoCall } from '../../models/video-call';
import { ResendVideoCallInvitationUseCase } from '../../services/ResendVideoCallInvitationUseCase';
import { StartVideoCallUseCase } from '../../services/StartVideoCallUseCase';
import { StopVideoCallUseCase } from '../../services/StopVideoCallUseCase';
import { VideoCallModal } from '../../services/video-call-modal';
import { VideoCallGateway } from '../../services/VideoCallGateway';
import { VideoCallProvider } from '../../services/VideoCallProvider';
import { AlertResolutionPageActions, InstallationPageActions, VideoCallsActions } from '../actions/videoCall';

@Injectable()
export class VideoCallEffects {
    loadCurrentVideoCalls$ = createEffect(() =>
        this.actions$.pipe(
            ofType(startLoading),
            switchMap(() =>
                this.videoCallGateway
                    .getVideoCalls()
                    .pipe(map((videoCalls: VideoCall[]) => VideoCallsActions.videoCallsLoaded({ videoCalls }))),
            ),
        ),
    );

    startVideoCallRequested$ = createEffect(() =>
        this.actions$.pipe(
            ofType(InstallationPageActions.startVideoCallRequested, AlertResolutionPageActions.startVideoCallRequested),
            switchMap(({ siteId }) =>
                this.startVideoCallUseCase
                    .exec(siteId)
                    .pipe(map((videoCall: VideoCall) => VideoCallsActions.videoCallCreated({ siteId, videoCall }))),
            ),
        ),
    );

    sendVideoCallInvitationRequested$ = createEffect(() =>
        this.actions$.pipe(
            ofType(VideoCallsActions.sendVideoCallInvitationRequested),
            switchMap(({ videoCallId, siteId }) =>
                this.resendVideoCallInvitationUseCase
                    .exec(videoCallId)
                    .pipe(map((videoCall) => VideoCallsActions.videoCallInvitationSent({ siteId, videoCall }))),
            ),
        ),
    );

    showToasterMessageOnInvitationSent$ = createEffect(() =>
        this.actions$.pipe(
            ofType(VideoCallsActions.videoCallCreated, VideoCallsActions.videoCallInvitationSent),
            switchMap(({ siteId }) =>
                this.store.select(getSiteOwner(siteId)).pipe(
                    map((siteOwner) => {
                        const toasterMessage: ToasterVideoCallInvitationSentMessage = {
                            id: crypto.randomUUID(),
                            type: ToasterMessageType.VIDEO_CALL_INVITATION_SENT,
                            content: {
                                siteId: siteId,
                                userSiteName: getUserSiteName(siteOwner),
                                message: 'TOASTER_VIDEO_CALL_INVITATION_SENT_MESSAGE',
                            },
                        };

                        return addToasterMessage({ message: toasterMessage });
                    }),
                ),
            ),
        ),
    );

    closeVideoCallAfterRequested$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(VideoCallsActions.videoCallStoppedRequested),
                tap(({ siteId }) => {
                    this.videoCallProvider.leaveVideoCall();
                    this.stopVideoCallUseCase.exec(siteId);
                    this.videoCallModal.close();
                }),
            ),
        { dispatch: false },
    );

    closeVideoCallAfterStopped$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(VideoCallsActions.videoCallStopped),
                tap(() => {
                    this.videoCallModal.close();
                }),
            ),
        { dispatch: false },
    );

    constructor(
        private actions$: Actions,
        @Inject('VideoCallGateway') private videoCallGateway: VideoCallGateway,
        @Inject('VideoCallProvider') private videoCallProvider: VideoCallProvider,
        private readonly startVideoCallUseCase: StartVideoCallUseCase,
        private readonly resendVideoCallInvitationUseCase: ResendVideoCallInvitationUseCase,
        private stopVideoCallUseCase: StopVideoCallUseCase,
        private store: Store<AppState>,
        private videoCallModal: VideoCallModal,
    ) {}
}
