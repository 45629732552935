import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { VideoCallModalData } from '../../models/video-call';
import { VideoCallComponent } from '../video-call/video-call.component';

@Component({
    selector: 'app-video-call-modal',
    templateUrl: './video-call-modal.component.html',
    styleUrls: ['./video-call-modal.component.scss'],
    standalone: true,
    imports: [TranslateModule, VideoCallComponent],
})
export class VideoCallModalComponent {
    constructor(@Inject(MAT_DIALOG_DATA) public data: VideoCallModalData) {}
}
