import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslatePipe } from '@ngx-translate/core';
import { AppState } from '../../../shared/store/app-state';
import { UiVideoCallsActions } from '../../store/actions/uiVideoCall';
import { TOGGLE_SCREEN_MODE, VideoCallScreenMode } from '../../utils/video-call-dialog-config';

@Component({
    selector: 'app-video-call-fullscreen-button',
    standalone: true,
    imports: [TranslatePipe],
    templateUrl: './video-call-fullscreen-button.component.html',
    styleUrl: './video-call-fullscreen-button.component.scss',
})
export class VideoCallFullscreenButtonComponent {
    @Input() screenMode: VideoCallScreenMode;

    constructor(private store: Store<AppState>) {}

    handleVideoCallFullScreenToggle(): void {
        this.store.dispatch(
            UiVideoCallsActions.videoCallScreenModeToggled({
                screenMode: TOGGLE_SCREEN_MODE[this.screenMode],
            }),
        );
    }
}
