import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { SiteId } from '../../../site-detail/models/Site';
import { VideoCall, VideoCallId, VideoCallStatus } from '../../models/video-call';

export const VideoCallsActions = createActionGroup({
    source: 'VideoCalls',
    events: {
        videoCallsLoaded: props<{ videoCalls: VideoCall[] }>(),
        videoCallCreated: props<{ siteId: SiteId; videoCall: VideoCall }>(),
        sendVideoCallInvitationRequested: props<{ videoCallId: VideoCallId; siteId: SiteId }>(),
        videoCallInvitationSent: props<{ siteId: SiteId; videoCall: VideoCall }>(),
        videoCallStoppedRequested: props<{ siteId: SiteId }>(),
        videoCallStopped: props<{ siteId: SiteId }>(),
        videoCallStatusUpdated: props<{ siteId: SiteId; status: VideoCallStatus }>(),
    },
});

export const VideoCallsNotificationActions = createActionGroup({
    source: 'VideoCalls Notification',
    events: {
        videoCallOpened: emptyProps(),
    },
});

export const InstallationPageActions = createActionGroup({
    source: 'Installation Page',
    events: {
        videoCallOpened: emptyProps(),
        startVideoCallRequested: props<{ siteId: SiteId }>(),
    },
});

export const AlertResolutionPageActions = createActionGroup({
    source: 'Alert Resolution Page',
    events: {
        videoCallOpened: emptyProps(),
        startVideoCallRequested: props<{ siteId: SiteId }>(),
    },
});
