import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { filter } from 'rxjs/operators';
import { ModalErrorComponent } from '../../shared/components/modal/modal-error/modal-error.component';
import { ConfirmDialogComponent } from '../components/dummies-components/device/modals/confirm-dialog/confirm-dialog.component';
import { StateEditionModalComponent } from '../components/dummies-components/device/modals/state-edition-modal/state-edition-modal.component';
import { DeviceId } from '../models/Device';
import { PropertyId } from '../models/DeviceDetail';

@Injectable({
    providedIn: 'root',
})
export class DialogService {
    constructor(private dialog: MatDialog) {}

    openEditDialog(deviceId: DeviceId, propertyId: PropertyId) {
        return this.dialog
            .open(StateEditionModalComponent, {
                disableClose: true,
                data: { deviceId, propertyId },
            })
            .afterClosed()
            .pipe(filter((editData) => editData?.newValue != null));
    }

    openWarningDialog(title: string, message: string) {
        this.dialog.open(ModalErrorComponent, {
            disableClose: true,
            data: { title, body: message },
        });
    }

    openRebootBoxConfirmationDialog() {
        return this.dialog
            .open(ConfirmDialogComponent, {
                data: { title: 'BOX_REBOOT', message: 'BOX_REBOOT_CONTENT' },
            })
            .afterClosed()
            .pipe(filter(Boolean));
    }

    openVideoCallConfirmationDialog() {
        return this.dialog
            .open(ConfirmDialogComponent, {
                data: {
                    title: 'VIDEO_CALL_CONFIRM_INVITE_TITLE',
                    message: 'VIDEO_CALL_CONFIRM_INVITE_CONTENT',
                    validButtonLabel: 'VIDEO_CALL_SEND_INVITATION',
                },
            })
            .afterClosed()
            .pipe(filter(Boolean));
    }
}
