import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import { VIDEO_CALL_SCREEN_MODE } from '../../utils/video-call-dialog-config';
import { UiVideoCallsActions } from '../actions/uiVideoCall';
import { VideoCallsActions } from '../actions/videoCall';

@Injectable()
export class UIVideoCallEffects {
    resetScreenModeAfterVideoCallClosed$ = createEffect(() =>
        this.actions$.pipe(
            ofType(VideoCallsActions.videoCallStopped),
            map(() =>
                UiVideoCallsActions.videoCallScreenModeToggled({ screenMode: VIDEO_CALL_SCREEN_MODE.FULLSCREEN }),
            ),
        ),
    );

    constructor(private actions$: Actions) {}
}
