<div class="toaster-root-container">
  @for (message of messagesToDisplay; track message.id) {
    <div class="no-padding">
      <div class="toaster-message-container">
        @if (message.type === 'DEVICE_UPDATE_STATE') {
          <app-device-update-item [content]="message.content" [id]="message.id"></app-device-update-item>
        }
        @if (message.type === 'ACCESS_GRANTED') {
          <app-access-granted-item [content]="message.content" [id]="message.id"></app-access-granted-item>
        }
        @if (mailSentTypes.includes(message.type)) {
          <app-mail-sent-item [content]="message.content" [id]="message.id"></app-mail-sent-item>
        }
        @if (message.type === 'SERVEGO_ACCESS_CHANGED') {
          <app-servego-changed-item [content]="message.content" [id]="message.id"></app-servego-changed-item>
        }
        @if (message.type === 'DEVICE_REFRESH_STATE_FAILED') {
          <app-device-refresh-state-failed-item [content]="message.content" [id]="message.id"></app-device-refresh-state-failed-item>
        }
        @if (message.type === 'SITE_DEVICE_REQUEST_ERROR') {
          <app-site-device-error-item [content]="message.content" [id]="message.id"></app-site-device-error-item>
        }
        @if (message.type === 'ERROR') {
          <app-error-item [content]="message.content" [id]="message.id" [duration]="message.duration"></app-error-item>
        }
        @if (message.type === 'CONFIRM_SUCCESS') {
          <app-toaster-success [content]="message.content" [id]="message.id" [duration]="message.duration"></app-toaster-success>
        }
        @if (message.type === 'INSTALLER_RECEIVE_NEW_MESSAGE') {
          <app-mail-received-item [content]="message.content" [id]="message.id" [duration]="message.duration"></app-mail-received-item>
        }
        @if (message.type === 'GUEST_JOINED_VIDEO_CALL') {
          <app-video-call-toaster-item [content]="message.content" [messageId]="message.id"></app-video-call-toaster-item>
        }
      </div>
    </div>
  }
</div>
