import { Component, Input } from '@angular/core';
import { SiteId } from '../../../site-detail/models/Site';
import { VideoCallScreenMode } from '../../utils/video-call-dialog-config';
import { VideoCallCloseButtonComponent } from '../video-call-close-button/video-call-close-button.component';
import { VideoCallFullscreenButtonComponent } from '../video-call-fullscreen-button/video-call-fullscreen-button.component';

@Component({
    selector: 'app-video-call-controls',
    standalone: true,
    imports: [VideoCallFullscreenButtonComponent, VideoCallCloseButtonComponent],
    templateUrl: './video-call-controls.component.html',
    styleUrl: './video-call-controls.component.scss',
})
export class VideoCallControlsComponent {
    @Input() siteId: SiteId;
    @Input() screenMode: VideoCallScreenMode;
}
