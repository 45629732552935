import { CdkDrag } from '@angular/cdk/drag-drop';
import { AsyncPipe } from '@angular/common';
import { Component, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AutoDestroy } from '../../../shared/annotations/autodestroy';
import { AppState } from '../../../shared/store/app-state';
import { VideoCallModalData } from '../../models/video-call';
import { VideoParticipants } from '../../models/video-call-participant';
import { VideoCallParticipantManager } from '../../services/VideoCallParticipantManager';
import { VideoCallProvider } from '../../services/VideoCallProvider';
import { getVideoCallScreenMode } from '../../store/selectors/ui-video-call';
import {
    DIALOG_CONFIG,
    TOGGLE_SCREEN_MODE,
    VIDEO_CALL_SCREEN_MODE,
    VideoCallScreenMode,
} from '../../utils/video-call-dialog-config';
import { VideoCallControlsComponent } from '../video-call-controls/video-call-controls.component';
import { VideoCallFullscreenButtonComponent } from '../video-call-fullscreen-button/video-call-fullscreen-button.component';
import { VideoCallModalComponent } from '../video-call-modal/video-call-modal.component';
import { VideoTileComponent } from '../video-tile/video-tile.component';

@Component({
    selector: 'app-video-call',
    standalone: true,
    imports: [AsyncPipe, CdkDrag, VideoCallControlsComponent, VideoCallFullscreenButtonComponent, VideoTileComponent],
    templateUrl: './video-call.component.html',
    styleUrl: './video-call.component.scss',
})
export class VideoCallComponent implements OnInit, OnDestroy {
    @AutoDestroy componentDestroyed$ = new Subject<void>();
    @Input() videoCallData: VideoCallModalData;

    videoParticipants$: Observable<VideoParticipants>;

    screenMode$: Observable<VideoCallScreenMode>;
    screenMode: VideoCallScreenMode;

    protected readonly VIDEO_CALL_SCREEN_MODE = VIDEO_CALL_SCREEN_MODE;

    @ViewChild(CdkDrag) dragElement: CdkDrag;

    constructor(
        @Inject('VideoCallProvider') private videoCallProvider: VideoCallProvider,
        private videoCallParticipantManager: VideoCallParticipantManager,
        private dialogRef: MatDialogRef<VideoCallModalComponent>,
        private store: Store<AppState>,
    ) {}

    ngOnInit(): void {
        this.screenMode$ = this.store.select(getVideoCallScreenMode);

        this.videoParticipants$ = this.videoCallParticipantManager.getParticipants();

        this.videoCallProvider.initializeVideoCall(this.videoCallData.url, this.videoCallData.ownerToken);

        this.screenMode$.pipe(takeUntil(this.componentDestroyed$)).subscribe((screenMode) => {
            this.handleFullscreenChange(screenMode);
        });
    }

    private handleFullscreenChange(screenMode: VideoCallScreenMode): void {
        this.screenMode = screenMode;

        if (screenMode === VIDEO_CALL_SCREEN_MODE.FULLSCREEN && this.dragElement) {
            this.dragElement.reset();
        }

        this.updateVideoCallModalScreen(this.dialogRef, screenMode);
    }

    private updateVideoCallModalScreen(
        dialogRef: MatDialogRef<VideoCallModalComponent>,
        mode: VideoCallScreenMode,
    ): void {
        const config = DIALOG_CONFIG[mode];
        const backdropElement = document.querySelector('.video-call-modal-backdrop');

        dialogRef.updateSize(config.size.width, config.size.height);
        dialogRef.updatePosition(config.position);

        dialogRef.addPanelClass(mode);
        dialogRef.removePanelClass(TOGGLE_SCREEN_MODE[mode]);

        backdropElement?.classList.add(mode);
        backdropElement?.classList.remove(TOGGLE_SCREEN_MODE[mode]);
    }

    ngOnDestroy(): void {
        this.videoCallProvider.destroy();
    }
}
