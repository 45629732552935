<div class="seg-modal">
  <div class="seg-modal-header">
    <h4 mat-dialog-title class="seg-modal-title">
      {{ (readonly ? 'RESEND_ASSIGNATION_REQUEST_BTN' : 'ATTACH_ALREADY_ACTIVATED_GATEWAY') | translate }}
    </h4>
  </div>
  <mat-dialog-content class="seg-modal-body">
    <form autocomplete="off" [formGroup]="emailAndServegoOptionForm" [ngClass]="{ readonly: readonly }">
      <p> {{ (readonly ? 'MODAL_ATTACH_GATEWAY_TEXT1': 'CRITERIA_SEARCH_PIN_CODE') | translate }} :</p>
      <input class="pin-code"
        autocomplete="off"
        formControlName="gatewayId"
        name="gatewayId"
        id="gatewayId"
        [readOnly]="readonly"
        type="text"
        [dropSpecialCharacters]="false"
        mask="0000-0000-0000"
        [showMaskTyped]="true"
        placeHolderCharacter="0"
        />

      <p>{{ (readonly ? 'MODAL_ATTACH_GATEWAY_TEXT2_EDIT_EMAIL' : 'MODAL_ATTACH_GATEWAY_TEXT2') | translate }} :</p>
      <input required
        class="email-input"
        type="email"
        id="attachGatewayEmail"
        formControlName="attachGatewayEmail"
        name="attachGatewayEmail"
        />

      <p class="bottom">{{ 'MODAL_ATTACH_GATEWAY_TEXT3' | translate }}</p>
    </form>

    @if (attachRequestErrorCode) {
      <div class="modal-errorMessage">
        <p class="modal-errorMessage-text">{{ generateLocalizedErrorKey() | translate }}</p>
      </div>
    }
  </mat-dialog-content>
  <mat-dialog-actions class="seg-flex-justify-space-around">
    <button id="btn-cancel"
      class="seg-btn seg-bg-color-secondary"
      mat-button
      (click)="cancelAttachGateway()"
    >{{ 'CANCEL' | translate }}</button>
    <button id="btn-validate"
            class="seg-btn seg-bg-color-primary"
            mat-button
            [disabled]="!emailAndServegoOptionForm.valid || loading"
            (click)="attachGateway();"
    >{{ 'VALIDATE' | translate }}</button>
  </mat-dialog-actions>
</div>
