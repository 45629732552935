import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../shared/store/app-state';
import { SiteId } from '../../../site-detail/models/Site';
import { VideoCallsActions } from '../../store/actions/videoCall';

@Component({
    selector: 'app-video-call-close-button',
    standalone: true,
    imports: [],
    templateUrl: './video-call-close-button.component.html',
    styleUrl: './video-call-close-button.component.scss',
})
export class VideoCallCloseButtonComponent {
    @Input() siteId: SiteId;

    constructor(private store: Store<AppState>) {}

    leaveCall(): void {
        this.store.dispatch(VideoCallsActions.videoCallStoppedRequested({ siteId: this.siteId }));
    }
}
